.FilterModal {
  position: fixed !important;
  width: 50vw !important;
  top: 20%;
  z-index: 9999 !important;
}
.closemodule {
  margin-top: 10px !important;
}
.chartShow{
  width: "500px";
  margin-left:"30%";
}