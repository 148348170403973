.FilterModal {
  position: fixed !important;
  width: 50vw !important;
  top: 20%;
  z-index: 9999 !important;
}
.closemodule {
  margin-top: 10px !important;
}
.MassUploadModal {
  position: fixed !important;
  width: 50vw !important;
  top: 20%;
  left: 43.5%; /* Center align horizontally */
  transform: translateX(-50%); /* Center the modal */
  z-index: 9999 !important;
}
.MassUpdateModal {
  position: fixed !important;
  width: 50vw !important;
  top: 15%;
  left: 55%; /* Center align horizontally */
  transform: translateX(-50%); /* Center the modal */
  z-index: 9999 !important;
}
.EditMailModule {
  position: fixed !important;
  width: 50vw !important;
  top: 7%;
  left: 55%; /* Center align horizontally */
  transform: translateX(-50%); /* Center the modal */
  z-index: 9999 !important;
}
.MassDeleteModule {
  position: fixed !important;
  width: 50vw !important;
  top: 7%;
  left: 55%; /* Center align horizontally */
  transform: translateX(-50%); /* Center the modal */
  z-index: 9999 !important;
}
.MassUpdateModalConfirm {
  position: fixed !important;
  width: 50vw !important;
  top: 15%;
  left: 55%; /* Center align horizontally */
  transform: translateX(-50%); /* Center the modal */
  z-index: 9999 !important;
}
.MassUploadModalConfirm {
  position: fixed !important;
  width: 50vw !important;
  top: 15%;
  left: 55%; /* Center align horizontally */
  transform: translateX(-50%); /* Center the modal */
  z-index: 9999 !important;
}
.LeadtoProspectConfirm {
  position: fixed !important;
  width: 50vw !important;
  top: 15%;
  left: 55%; /* Center align horizontally */
  transform: translateX(-50%); /* Center the modal */
  z-index: 9999 !important;
}
.MassMailConfirmModule {
  position: fixed !important;
  width: 50vw !important;
  top: 15%;
  left: 55%; /* Center align horizontally */
  transform: translateX(-50%); /* Center the modal */
  z-index: 9999 !important;
}
.OultlookMailModule {
  position: fixed !important;
  width: 50vw !important;
  top: 15%;
  left: 55%; /* Center align horizontally */
  transform: translateX(-50%); /* Center the modal */
  z-index: 9999 !important;
}
.DownloadExcelModule {
  position: fixed !important;
  width: 50vw !important;
  top: 15%;
  left: 55%; /* Center align horizontally */
  transform: translateX(-50%); /* Center the modal */
  z-index: 9999 !important;
}
.MeetingModal{
  position: fixed !important;
  width: 50vw !important;
  top: 15%;
  left: 55%; /* Center align horizontally */
  transform: translateX(-50%); /* Center the modal */
  z-index: 9999 !important;
}
.CallsModal{
  position: fixed !important;
  width: 50vw !important;
  top: 15%;
  left: 55%; /* Center align horizontally */
  transform: translateX(-50%); /* Center the modal */
  z-index: 9999 !important;
}