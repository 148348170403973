/* Base styles for all screen sizes */
.error {
  font-size: 16px;
  font-family: Arial, Helvetica, sans-serif;
  text-align: center; /* Center the text */
}

.error h1 {
  font-size: 20pt;
  font-weight: bold;
  margin-top: -190px;
}

.error p {
  margin-top: 10px; /* Use margin-top to create space between the h1 and p elements */
}

.imgerror {
  display: block; /* Center the image by setting it as a block element */
  margin: 0 auto; /* Automatically center the image horizontally */
  max-width: 50%; /* Limit the image width to 50% */
}