.singleEditModal {
  position: fixed !important;
  width: 50vw !important;
  top: 7%;
  left: 50%; /* Center align horizontally */
  transform: translateX(-50%); /* Center the modal precisely */
  z-index: 9999 !important;
}
.tooltip {
  width: 10vw !important;
  position: fixed !important;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999 !important;
}


.tableRowSelected:hover{
background-color: rgba(87, 87, 87, 0.123) !important;
cursor: pointer;
}
