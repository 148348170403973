.suggestions {
  position: absolute;
  font-size: 12pt;
  font-weight: 500;
  top: 100%;
  left: 0;
  right: 0;
  background-color: #e2e8f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  scroll-behavior: auto;
}

.suggestion-item {
  padding: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
}

.suggestion-item:hover {
  background-color: #f0f0f0;
  font-size: 12pt;
  font-weight: 500;
  color: #002d74;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.no-matching-reporters {
  padding: 8px;
  font-size: 12pt;
  font-weight: bold;
  color: #ff0000;
}
