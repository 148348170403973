.cards{
    background-color: #F5F5F5;
    margin-top: 20px;
}
.css-1auuqwc{
    border-radius: 10px;
}
.css-wru3b5{
    border-radius: 10px;
}
.css-wru3b5 .h2{
    font-size:medium;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 600;
}
.css-kyxmw6{
    border-radius: 10px;
}
.css-kyxmw6 .h2{
    font-size:medium;
    font-family: Arial, Helvetica, sans-serif;
    color: #E7E7F8;
}