* {
  box-sizing: border-box;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  padding: 10px;
  height: 300px; /* Should be removed. Only for demonstration */
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
.card-center{
  background-color: whitesmoke;
  border-radius: 5px
}
/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
  }
}




.rotating-text-wrapper {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  top: 90px;
}
.rotating-text-wrapper h2 {
  font-family: "Times New Roman", Times, serif;
  font-size: 2.5em;
  color: #fff;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  background-color: darkblue;
  border-radius: 5px;
}
/* end */
/* .rotating-text-wrapper h2,
h3,
h4 {
  font-family: "Times New Roman", Times, serif;
  font-size: 2.5em;
  color: #fff;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.2);
  animation-duration: 6s;
  animation-iteration-count: infinite;
  opacity: 0;
  background-color: darkblue;
  animation-name: rotating-text-1;
}

.rotating-text-wrapper h3 {
  background-color: tomato;
  animation-name: rotating-text-2;
}

.rotating-text-wrapper h4 {
  background-color: cornflowerblue;
  animation-name: rotating-text-3;
} */

/* @keyframes rotating-text-1 {
  0% {
    transform: translateY(200%);
  }
  33% {
    transform: translateY(100%);
    opacity: 1;
  }
  34% {
    opacity: 0;
  }
}

@keyframes rotating-text-2 {
  33% {
    transform: translateY(100%);
    opacity: 0;
  }
  66% {
    transform: translateY(0);
    opacity: 1;
  }
  67% {
    opacity: 0;
  }
}

@keyframes rotating-text-3 {
  66% {
    transform: translateY(0);
    opacity: 0;
  }
  99% {
    transform: translateY(-100%);
    opacity: 1;
  }
  100% {
    transform: translateY(-100%);
    opacity: 0;
  }
} */
/* end */
/* @media screen and (max-width: 600px) {
  .rotating-text-wrapper {
    font-size: 12pt;
    top: 210px;
  }
  .video-control {
    width: auto;
    max-height: 100%;
  }
}
@media (max-width: 820px) {
  .rotating-text-wrapper {
    font-size: 10px;
    top: 210px;
  }
  .video-control {
    width: auto;
    height: h-screen;
  }
} */
