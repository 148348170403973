.header-input[type="checkbox"][type="checkbox"] {
  width: 20px;
  height: 20px;
  border: 2px solid #ccc;
  float: left;
  margin-left: 10px;
  border-radius: 4px;
  padding: 10px 10px;
  outline: none;
  transition: border-color 0.3s ease-in-out;
  margin-right: 10px;
  cursor: pointer;
}
